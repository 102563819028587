/**
 * Created by Aleksandr on 05.04.2023
 */
cleverapps.overrideColors(cleverapps.styles.COLORS, {
    BLOCK_COLOR_RED: new cc.Color(240, 36, 53, 255),
    BLOCK_COLOR_GREEN: new cc.Color(34, 209, 2, 255),
    BLOCK_COLOR_BLUE: new cc.Color(13, 177, 248, 255),
    BLOCK_COLOR_YELLOW: new cc.Color(255, 234, 0, 255),
    BLOCK_COLOR_PURPLE: new cc.Color(137, 22, 221, 255),
    BLOCK_COLOR_CYAN: new cc.Color(254, 48, 180, 255),
    BLOCK_COLOR_ORANGE: new cc.Color(255, 116, 5, 255)
});

cleverapps.overrideStyles(cleverapps.styles.LoseWindow, {
    noBackground: true
});

cleverapps.overrideStyles(cleverapps.styles.BlocksGameScene, {
    highScore: [{
        x: { align: "center" },
        y: { align: "top", dy: -122 }
    }, {
        x: { align: "center" },
        y: { align: "top", dy: -122 }
    }, {
        x: { align: "center", dx: -100 },
        y: { align: "top", dy: -122 }
    }],

    score: [{
        x: { align: "center", dx: 125 },
        y: { align: "center", dy: 500 }
    }, {
        x: { align: "center", dx: 125 },
        y: { align: "center", dy: 500 }
    }, {
        x: { align: "center", dx: 25 },
        y: { align: "center", dy: 500 }
    }]
});

cleverapps.overrideStyles(cleverapps.styles.GridView, {
    cellOffset: {
        x: -13,
        y: 1
    }
});

cleverapps.overrideColors(cleverapps.styles.COLORS, {
    HIGHSCORE_COLOR: new cc.Color(129, 69, 36, 255)
});

cleverapps.overrideStyles(cleverapps.styles.UI.HighScoreView, {
    bg: {
        width: 550,
        height: 100
    },

    text: {
        x: { align: "left", dx: 115 },
        y: { align: "center" }
    },

    icon: {
        x: { align: "left", dx: 10 },
        y: { align: "center", dy: 8 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UI.ScoreView, {
    bg: {
        width: 250,
        height: 80
    }
});

cleverapps.overrideStyles(cleverapps.styles.VictoryWindow, {
    padding: {
        top: 100,
        bottom: 40
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    WINDOW_TEXT: {
        name: "nostroke",
        size: 33,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.overrideStyles(cleverapps.styles.ScoreStatsComponent, {
    background: false
});